import { Link } from "react-router-dom";
import Schema from "./../images/schema.svg";

const Hero = () => {
  return (
    <div className="flex items-center flex-col md:justify-center">
      <section className="flex lg:justify-between justify-center items-center lg:flex-row flex-col">
        <div className="relative md:w-full xl:w-1/2 flex flex-col justify-center sm:items-center mt-10 z-10">
          <h1 className="text-[#ececec] md:text-5xl sm:text-2xl text-4xl mb-12 font-bold tracking-wider">
            I'm a developer who builds creative{" "}
            <span className="text-[#6290C3]">digital experiences</span>
          </h1>
          <p className="text-[#ececec] md:text-2xl text-lg tracking-wide leading-relaxed">
            Greetings, my name is Augustas, a software developer who enjoys
            developing creative applications. I have experience with frontend &
            backend.
          </p>
          <Link to="/projects">
            <button className="text-[#C2E7DA] text-xl border-2 my-5 font-medium text-center tracking-wide rounded-full px-7 py-2 border-[#C2E7DA] hover:bg-[#C2E7DA] hover:text-[#1a1b41]">
              Projects
            </button>
          </Link>
        </div>
        <div className="flex lg:w-1/2 w-2/3 justify-center flex-col items-center -z-10">
          <img src={Schema} alt="schema" />
        </div>
      </section>
    </div>
  );
};

export default Hero;
