import { FaGithub, FaLinkedin } from "react-icons/fa";

const Footer = () => {
  return (
    <div className="flex md:items-center md:flex-row flex-col-reverse md:justify-between md:w-full md:py-8 py-4 border-t border-[#C2E7DA]/20">
      <div className="flex items-center justify-center">
        <span className="text-[#ececec] text-sm opacity-70">
          &copy; Copyright Augustas Matizonka 2023
        </span>
      </div>
      <div className="flex md:flex-row py-2 items-center justify-center">
        <a
          href="https://github.com/Safiukas?tab=repositories"
          rel="noreferrer"
          target="_blank"
        >
          <FaGithub className="md:text-4xl text-3xl mx-2 text-[#ececec]/70 hover:text-[#c9510c] hover:cursor-pointer z-10" />
        </a>
        <a
          href="https://www.linkedin.com/in/augustas-matizonka-864b93208/"
          rel="noreferrer"
          target="_blank"
        >
          <FaLinkedin className="md:text-4xl text-3xl mx-2  text-[#ececec]/70 hover:text-[#0e76a8] hover:cursor-pointer z-10" />
        </a>
      </div>
    </div>
  );
};

export default Footer;
