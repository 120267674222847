import { useState } from "react";
import { FaBars } from "react-icons/fa";
import { Link, NavLink } from "react-router-dom";
import MobileNav from "./MobileNav";

const Header = () => {
  const normalLink =
    "text-[#ececec] font-medium text-xl mx-4 hover:text-[#C2E7DA] hover:cursor-pointer tracking-wide";
  const activeLink =
    "text-[#ececec] font-medium text-xl mx-4 hover:text-[#C2E7DA] hover:cursor-pointer tracking-wide underline underline-offset-4 decoration-[#BAFF29]";

  const [active, setActive] = useState(false);

  const showMenu = () => {
    setActive(!active);
  };

  return (
    <div className="flex sticky items-center justify-center bg-[#101029] z-30">
      <section className="flex justify-between items-center w-screen mt-7">
        <div className="flex items-center text-[#BAFF29] md:text-xl text-md uppercase font-bold tracking-wider">
          <Link to="/">Augustas Matizonka</Link>
        </div>

        <div className="flex items-center">
          <nav className="flex items-center mx-3">
            <div className="flex items-center justify-center lg:hidden">
              <FaBars
                onClick={showMenu}
                className="text-3xl text-[#ececec] cursor-pointer"
              />
            </div>
            <div className="hidden lg:block">
              <NavLink
                exact="true"
                to="/"
                className={({ isActive }) =>
                  isActive ? activeLink : normalLink
                }
              >
                Home
              </NavLink>
              <NavLink
                to="/skills"
                className={({ isActive }) =>
                  isActive ? activeLink : normalLink
                }
              >
                Skills
              </NavLink>
              <NavLink
                to="/projects"
                className={({ isActive }) =>
                  isActive ? activeLink : normalLink
                }
              >
                Projects
              </NavLink>
              <NavLink
                to="/about"
                className={({ isActive }) =>
                  isActive ? activeLink : normalLink
                }
              >
                About Me
              </NavLink>
              <Link to="/contact">
                <button className="text-[#C2E7DA] text-xl border-2 font-medium text-center tracking-wide rounded-full px-7 py-2 border-[#C2E7DA] hover:bg-[#C2E7DA] hover:text-[#1a1b41]">
                  Contact
                </button>
              </Link>
            </div>

            <MobileNav showMenu={showMenu} active={active} />
          </nav>
        </div>
      </section>
    </div>
  );
};

export default Header;
