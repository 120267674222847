import Html from "../images/techStack/html5.svg";
import Css from "../images/techStack/css.svg";
import Js from "../images/techStack/js.svg";
import Bootstrap from "../images/techStack/bootstrap.svg";
import Mongo from "../images/techStack/mongo.svg";
import Node from "../images/techStack/nodejs.svg";
import ReactJs from "../images/techStack/reactjs.svg";
import Redux from "../images/techStack/redux.svg";
import Tailwind from "../images/techStack/tailwindcss.svg";
import Npm from "../images/techStack/npm.svg";
import Express from "../images/techStack/expressjs.svg";
import Jwt from "../images/techStack/jwt.svg";
import Git from "../images/techStack/git.svg";

const Cards = () => {
  return (
    <section className="flex items-center justify-center md:my-10 lg:my-5 flex-col-reverse xl:flex-row">
      <div className="md:w-1/2">
        <div className="flex items-center justify-center">
          <img
            src={Html}
            alt="tech logo"
            className="xl:w-16 w-12 md:mx-14 mx-5 md:my-4 my-2"
          />
          <img
            src={Css}
            alt="tech logo"
            className="xl:w-12 scale-150 md:mx-14 mx-5 md:my-4 my-2"
          />
          <img
            src={Bootstrap}
            alt="tech logo"
            className="xl:w-16 w-12 md:mx-14 mx-5 md:my-4 my-2"
          />
          <img
            src={Tailwind}
            alt="tech logo"
            className="xl:w-16 w-12 md:mx-14 mx-5 md:my-4 my-2"
          />
        </div>

        <div className="flex items-center justify-center">
          <img
            src={Js}
            alt="tech logo"
            className="xl:w-16 w-12 md:mx-8 mx-4 md:my-4 my-2"
          />
          <img
            src={Node}
            alt="tech logo"
            className="xl:w-16 w-12 md:mx-8 mx-4 md:my-4 my-2"
          />
          <img
            src={Express}
            alt="tech logo"
            className="xl:w-16 w-12 md:mx-8 mx-4 md:my-4 my-2"
          />
          <img
            src={Mongo}
            alt="tech logo"
            className="xl:w-16 w-12 md:mx-8 mx-4 md:my-4 my-2"
          />
        </div>

        <div className="flex items-center justify-center">
          <img
            src={ReactJs}
            alt="tech logo"
            className="xl:w-16 w-12 md:mx-10 mx-4 md:my-4 my-4"
          />
          <img
            src={Redux}
            alt="tech logo"
            className="xl:w-16 w-12 md:mx-10 mx-4 md:my-4 my-4"
          />
          <img
            src={Git}
            alt="tech logo"
            className="xl:w-16 w-12 md:mx-10 mx-4 md:my-4 my-4"
          />
        </div>
        <div className="flex items-center justify-center">
          <img
            src={Npm}
            alt="tech logo"
            className="xl:w-16 w-12 md:mx-10 mx-4 md:my-4 my-4"
          />
          <img
            src={Jwt}
            alt="tech logo"
            className="xl:w-16 w-12 md:mx-10 mx-4 md:my-4 my-4"
          />
        </div>
      </div>

      <div className="w-full flex items-center justify-center">
        <h1 className="text-[#ececec] xl:text-5xl text-3xl mb-12 font-bold tracking-wider underline underline-offset-4 decoration-[#BAFF29]">
          Tools & Technologies
        </h1>
      </div>
    </section>
  );
};

export default Cards;
