import Cubes from "../images/cubes.svg";
import { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useCopyToClipboard from "./CopyToClipboard";
import { FaCopy } from "react-icons/fa";

const successToast = (message) => {
  toast.success(message, {
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });
};

const errToast = (message) =>
  toast.error(message, {
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });

const Contact = () => {
  const [copyToClipboard, { success }] = useCopyToClipboard();

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    if (!name || !email || !message) {
      errToast("Please fill all fields!");
    } else {
      emailjs
        .sendForm(
          "service_5z5yhjh",
          "template_87how3t",
          form.current,
          "AgXGwGJhtuGWAIA-0"
        )
        .then(
          (result) => {
            successToast("Message sent successfully!");
          },
          (error) => {
            errToast(`Something went wrong! ${error}`);
          }
        );
    }
  };

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  return (
    <>
      <div className="flex items-center flex-col md:flex-row justify-center my-6">
        <section className="flex md:justify-between items-center flex-col md:flex-row">
          <div className="relative md:w-1/2 md:mr-20 z-10 my-2">
            <h1 className="text-[#ececec] md:text-3xl text-2xl mb-12 uppercase font-bold tracking-wider underline underline-offset-4 decoration-[#BAFF29]">
              get in touch
            </h1>
            <p className="text-[#ececec] md:text-xl text-lg tracking-wide leading-relaxed">
              I'm always happy to hear about exciting opportunities, and build
              valuable connections. Let's connect!
            </p>
            <button
              onClick={() => copyToClipboard("augis05@gmail.com")}
              className="text-[#C2E7DA] flex items-center justify-center opacity-70 text-lg border-2 my-5 font-medium text-center tracking-wide rounded-full px-5 py-1 border-[#C2E7DA] hover:opacity-90 hover:text-[#ececec]"
            >
              {success ? "Copied to clipboard!" : "Copy Email"}
              <FaCopy className="mx-2 text-[#ececec]" />
            </button>
            <div className="absolute md:top-30 md:left-80 hidden lg:block w-1/2 -z-20">
              <img src={Cubes} alt="Tech Cubes" />
            </div>
          </div>

          <div className="md:w-1/2">
            <div className="flex items-center flex-col">
              <form ref={form} onSubmit={sendEmail}>
                <div className="flex flex-col ">
                  <label className="text-xl text-[#ececec] my-2">Name</label>
                  <input
                    type="text"
                    name="name"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    className="py-2 md:w-96 w-80 border border-[#ececec] focus:outline-none focus:border-[#BAFF29] bg-transparent rounded-md text-[#ececec] text-lg"
                  />
                </div>

                <div className="flex flex-col">
                  <label className="text-xl text-[#ececec] my-2">Email</label>
                  <input
                    type="email"
                    name="user_email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    className="py-2 md:w-96 border border-[#ececec] focus:outline-none focus:border-[#BAFF29] bg-transparent rounded-md text-[#ececec] text-lg"
                  />
                </div>

                <div className="flex flex-col">
                  <label className="text-xl text-[#ececec] my-2">Message</label>
                  <textarea
                    //   value={}
                    //   onChange={}
                    type="textarea"
                    name="message"
                    value={message}
                    onChange={(e) => {
                      setMessage(e.target.value);
                    }}
                    className="py-2 md:w-96 border border-[#ececec] focus:outline-none focus:border-[#BAFF29] bg-transparent rounded-md text-[#ececec] text-lg"
                    rows={5}
                    cols={5}
                  />
                </div>
                <div className="flex items-center justify-center mt-3">
                  <button
                    className="text-[#C2E7DA] text-xl border-2 my-5 font-medium text-center tracking-wide rounded-full px-10 py-2 border-[#C2E7DA] hover:bg-[#C2E7DA] hover:text-[#1a1b41]"
                    type="submit"
                  >
                    Send
                  </button>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
      <ToastContainer />
    </>
  );
};

export default Contact;
