import Me from "../images/Me.jpg";

const About = () => {
  return (
    <div className="md:my-3">
      <section className="">
        <div className="flex items-center md:flex-row-reverse md:justify-between flex-col md:my-20 my-3">
          <div className="flex flex-col md:w-1/2">
            <h1 className="text-[#ececec] md:text-4xl text-3xl mb-12 font-bold tracking-wider underline underline-offset-4 decoration-[#BAFF29] mt-5">
              Welcome,
            </h1>
            <p className="text-[#ececec] md:px-10 py-3 md:text-2xl text-lg tracking-wider">
              My name is Augustas Matizonka. I am a software developer, who
              enjoys developing creative applications. I have experience with
              frontend & backend.
            </p>
            <p className="text-[#ececec] md:px-10 py-3 md:text-2xl text-lg tracking-wider">
              I like to use my creativity to explore and build applications.
              Currently I am part of "100 Devs" agency which is based in Los
              Angeles, CA. I was working on several projects as well as
              collaborating with other engineers, along with contributing to
              open-source
            </p>
          </div>

          <div className="relative flex items-center justify-center md:w-1/2 my-10 -z-10">
            <img
              src={Me}
              className="md:w-1/2 w-48 rounded-md"
              alt="project visuals"
            />
            <div className="absolute md:w-80 w-60 h-4/5 md:top-40 md:right-72 hidden -z-20 border border-[#BAFF29]"></div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
