import { Link, NavLink } from "react-router-dom";
import { IoClose } from "react-icons/io5";

const MobileNav = ({ showMenu, active }) => {
  const normalLink =
    "text-[#ececec] font-medium text-xl mx-4 hover:text-[#C2E7DA] hover:cursor-pointer tracking-wide";
  const activeLink =
    "text-[#ececec] font-medium text-xl mx-4 hover:text-[#C2E7DA] hover:cursor-pointer tracking-wide underline underline-offset-4 decoration-[#BAFF29]";

  return (
    <>
      <ul
        className={
          active
            ? "flex-col flex items-center fixed inset-0 uppercase backdrop-blur-lg gap-10 justify-center p-8 xl:hidden"
            : "hidden"
        }
      >
        <IoClose
          className="cursor-pointer text-3xl text-[#ececec]"
          onClick={showMenu}
        />
        <li>
          <NavLink
            exact="true"
            to="/"
            onClick={showMenu}
            className={({ isActive }) => (isActive ? activeLink : normalLink)}
          >
            Home
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/skills"
            onClick={showMenu}
            className={({ isActive }) => (isActive ? activeLink : normalLink)}
          >
            Skills
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/projects"
            onClick={showMenu}
            className={({ isActive }) => (isActive ? activeLink : normalLink)}
          >
            Projects
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/about"
            onClick={showMenu}
            className={({ isActive }) => (isActive ? activeLink : normalLink)}
          >
            About Me
          </NavLink>
        </li>
        <li>
          <Link to="/contact">
            <button
              onClick={showMenu}
              className="text-[#C2E7DA] text-xl border-2 font-medium text-center tracking-wide rounded-full px-7 py-2 border-[#C2E7DA] hover:bg-[#C2E7DA] hover:text-[#1a1b41]"
            >
              Contact
            </button>
          </Link>
        </li>
      </ul>
    </>
  );
};

export default MobileNav;
