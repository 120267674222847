import { Outlet } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";

const Layout = () => {
  return (
    <>
      <main className="md:mx-20 mx-7 h-screen flex flex-col justify-between">
        <Header />
        <Outlet />
        <Footer />
      </main>
    </>
  );
};

export default Layout;
