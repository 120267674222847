import Glacier from "../images/Project1.gif";
import HoppManager from "../images/HoppManager.jpg";
import { FaGithub, FaEye } from "react-icons/fa";

const Projects = () => {
  return (
    <section className="my-3">
      <div className="flex items-center justify-center flex-col xl:px-20 md:flex-row-reverse xl:justify-between xl:my-10 xl:h-80 my-3">
        <div className="flex items-center flex-col xl:w-1/2 my-5">
          <h1 className="text-[#ececec] md:text-3xl text-2xl mb-12 font-bold tracking-wider underline underline-offset-4 decoration-[#BAFF29]">
            "HOPP" Manager
          </h1>
          <p className="text-[#ececec] md:px-10 text-xl text-center tracking-wider">
            Full stack managing application to maintain fleet, achieve the best
            shift performance and provide best quality for customers.
            Application is designed for shift managers as work tool. Supervisors
            have access to admin dashboard panel with various features, data
            visualization.
          </p>
          <div className="hidden md:flex md:flex-row flex-col my-7">
            <a
              href="https://github.com/Safiukas/hoppManager"
              rel="noreferrer"
              target="_blank"
            >
              <button className="text-[#C2E7DA] px-16 mx-2 text-xl border-2 font-medium text-center tracking-wide rounded-full py-2 border-[#C2E7DA] hover:bg-[#C2E7DA]/20 hover:text-[#1a1b41]">
                <FaGithub className="text-3xl mx-2 text-[#ececec]/70 hover:cursor-pointer z-10" />
              </button>
            </a>
          </div>
        </div>

        <div className="relative flex items-center justify-center flex-col xl:w-1/2">
          <img
            src={HoppManager}
            className="xl:w-3/5 rounded-md my-5"
            alt="project visuals"
          />
          <div className="flex md:flex-row flex-col my-3 md:hidden">
            <a
              href="https://github.com/Safiukas/hoppManager"
              rel="noreferrer"
              target="_blank"
            >
              <button className="text-[#C2E7DA] px-16 mx-2 text-xl border-2 font-medium text-center tracking-wide rounded-full py-2 border-[#C2E7DA] hover:bg-[#C2E7DA]/20 hover:text-[#1a1b41]">
                <FaGithub className="text-3xl mx-2 text-[#ececec]/70 hover:cursor-pointer z-10" />
              </button>
            </a>
          </div>
        </div>
      </div>

      <div className="flex items-center justify-center md:flex-row xl:px-20 flex-col xl:justify-between xl:my-10 xl:h-80 my-10">
        <div className="flex items-center flex-col xl:w-1/2">
          <h1 className="text-[#ececec] md:text-3xl text-2xl mb-12 font-bold tracking-wider underline underline-offset-4 decoration-[#BAFF29]">
            Glacier Adventures
          </h1>
          <p className="text-[#ececec] md:px-10 my-3 text-xl text-center font bold tracking-wider">
            Fully responsive travel agency website. Including variety of extreme
            activities and tours all around. Wonderful template for future
            business with booking and buy now functionality.
          </p>
          <div className="hidden md:flex md:flex-row flex-col my-7">
            <a
              href="https://glacier-adventures.netlify.app/index.html"
              rel="noreferrer"
              target="_blank"
            >
              <button className="text-[#C2E7DA] px-16 mx-2 text-xl border-2 font-medium text-center tracking-wide rounded-full  py-2 border-[#C2E7DA] hover:bg-[#C2E7DA]/20 hover:text-[#1a1b41]">
                <FaEye className="text-3xl mx-2 text-[#ececec]/70 hover:cursor-pointer z-10" />
              </button>
            </a>
            <a
              href="https://github.com/Safiukas/adventure_web"
              rel="noreferrer"
              target="_blank"
            >
              <button className="text-[#C2E7DA] px-16 mx-2 text-xl border-2 font-medium text-center tracking-wide rounded-full  py-2 border-[#C2E7DA] hover:bg-[#C2E7DA]/20 hover:text-[#1a1b41]">
                <FaGithub className="text-3xl mx-2 text-[#ececec]/70 hover:cursor-pointer z-10" />
              </button>
            </a>
          </div>
        </div>

        <div className="relative flex items-center justify-center flex-col xl:w-1/2">
          <img
            src={Glacier}
            className="xl:w-3/5 rounded-md my-5"
            alt="project visuals"
          />
          <div className="flex md:flex-row flex-col my-3 md:hidden">
            <div className="text-[#C2E7DA] px-16 mx-2 text-xl border-2 cursor-pointer font-medium text-center tracking-wide rounded-full py-2 border-[#C2E7DA] hover:bg-[#C2E7DA]/20 hover:text-[#1a1b41]">
              <a
                href="https://glacier-adventures.netlify.app/index.html"
                rel="noreferrer"
                target="_blank"
              >
                <FaEye className="text-3xl mx-2 text-[#ececec]/70 hover:cursor-pointer" />
              </a>
            </div>

            <a
              href="https://github.com/Safiukas/adventure_web"
              rel="noreferrer"
              target="_blank"
            >
              <button className="text-[#C2E7DA] px-16 mx-2 text-xl border-2 font-medium text-center tracking-wide rounded-full my-5 py-2 border-[#C2E7DA] hover:bg-[#C2E7DA]/20 hover:text-[#1a1b41]">
                <FaGithub className="text-3xl mx-2 text-[#ececec]/70 cursor-pointer" />
              </button>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Projects;
